<template>
  <el-container>
    <BaaSGovernanceNode />
  </el-container>
</template>

<script>
import { BaaSGovernanceNode } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSGovernanceNode,
  },
};
</script>
